import React from 'react';
import {Input,InputNumber} from 'antd';
import Hotable from './excel';
import Head from '../data-to-video/common/headIn';
import {cloneConfig} from "../util/handy";
import {data} from './data';


let config={
    level1:'',
    level2:'',
    level3:'',
    level4:'',

}
// const data =[["名称","值"],['搜索引擎',1048],['直接访问',735],['邮件营销',580],['联盟广告',484],['视频广告',300]];
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            data:cloneConfig(data),
            level1:['',0,0],
            level2:['',0,0],
            level3:['',0,0],
            level4:[0,0,0],
        }
    }
  componentWillMount() {

  }
  componentDidMount(){

  }
    changeData(){

    }
  render() {
    return (
      <div>
          <Head history={this.props.history}/>
          <div className="peak-area">
              <div className="title">
                  巅峰等级计算器
              </div>
              <div className="table">
                      <div><span>非赛巅峰经验：{this.state.level1[1].toLocaleString()}</span><span>
                    <InputNumber
                        placeholder="非赛巅峰等级"
                        value={this.state.level1[0]}
                        min={0}
                        max={203988}
                        step={1}
                        onChange={(e,v)=>{
                            let check=data[e+1];
                            if(!check)return;
                            this.state.level1=check;
                            let exp=this.state.level1[1]*1+this.state.level2[1]*1;
                            let sum=1;
                            for(let i=1;i<data.length;i++){
                                if(data[i][1]>=exp){
                                    sum=i;
                                    break;
                                }
                            }
                            let check3=data[sum];
                            if(!check3)return;
                            this.state.level3=check3;

                            this.setState({optionStep:0});
                        }}
                    /></span></div>
                      <div><span>赛季巅峰经验：{this.state.level2[1].toLocaleString()}</span><span>
                    <InputNumber
                        min={0}
                        max={203988}
                        step={1}
                        placeholder="赛季巅峰等级"
                        value={this.state.level2[0]}
                        onChange={(e,v)=>{
                            let check=data[e+1];
                            if(!check)return;
                            this.state.level2=check;
                            let exp=this.state.level1[1]*1+this.state.level2[1]*1;
                            let sum=1;
                            for(let i=1;i<data.length;i++){
                                if(data[i][1]>=exp){
                                    sum=i;
                                    break;
                                }
                            }
                            let check3=data[sum];
                            if(!check3)return;
                            this.state.level3=check3;
                            this.setState({optionStep:0});
                        }}/></span></div>
                      <div><span>合并巅峰经验：{this.state.level3[1].toLocaleString()}</span><span>
                    <InputNumber
                        min={0}
                        max={203988}
                        step={1}
                        placeholder="合并巅峰等级"
                        value={this.state.level3[0]}
                        onChange={(e,v)=>{
                            let check=data[e+1];
                            if(!check)return;
                            this.state.level3=check;
                            let exp=this.state.level3[1]*1-this.state.level1[1]*1;
                            let sum=1;
                            for(let i=1;i<data.length;i++){
                                if(data[i][1]>=exp){
                                    sum=i;
                                    break;
                                }
                            }
                            let check3=data[sum];
                            if(!check3)return;
                            this.state.level2=check3;
                            this.setState({optionStep:0});
                        }}/></span></div>
                      <div>
                          <span>巅峰等级：{this.state.level4[0].toLocaleString()}({this.state.level4[1].toLocaleString()})</span><span>
                    <InputNumber
                        placeholder="经验值（单位：T ）"
                        // value={this.state.level4[1]}
                        onChange={(e,v)=>{
                            // let check=data[e+1];
                            // if(!check)return;
                            // this.state.level3=check;
                            let exp=e*1000*1000*1000*1000;
                            let sum=23989;
                            for(let i=1;i<data.length;i++){
                                if(data[i][1]>=exp){
                                    sum=i;
                                    break;
                                }
                            }
                            let check3=data[sum];
                            if(!check3)return;
                            this.state.level4=check3;
                            this.setState({optionStep:0});
                        }}/></span>
                          {/*<span className="min">文本颜色：</span><span><Input/></span>*/}
                          <span className="min"> </span>
                          <span className="min"> </span>
                          <span className="min"> </span>
                      </div>

                        <div className="btn" onClick={()=>{
                            this.props.history.push('/peak-list');

                        }}>
                            等级经验数据
                        </div>
              </div>
          </div>
      </div>
    );
  }
}

export default App;
