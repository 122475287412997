import $ from 'jquery';
import { Modal, message } from 'antd';
/*
// const scope = 'http://127.0.0.1:5650';
// const scope = 'http://engrave.date:5650';
// const scope = 'http://dealpro.westus2.cloudapp.azure.com:5650';
// const scope = 'http://47.92.212.121:18082/backend';
let scope = '/dvapi';

if (process.env.NODE_ENV === 'production') {
    scope = '/dvapi/';
} else {
    // _scope = 'https://quickedit.pdnews.cn/apiservice/';
    // _scope = 'http://icutter.pdnews.cn/apiservice/';
    //plan a
    //   _scope = 'http://yima.c1.yinshibei.com:10028/backend/';

    scope = 'http://47.92.212.121:18082/dvapi';
    // _scope = 'https://mt-optest-http.jdcloud.com/apiservice/';
    // _scope = '//116.196.118.109:8081/';
}*/
const _warning = Modal.warning;

const ajax = (url, data, success, fail, type, async,auto) => {
  $.ajax({
    type: type || 'get',
    // async: async,
    dataType: 'json',
    // timeout:url.indexOf('get_video_url')>-1?20*1000:60*1000,
    // crossDomain: true,
    // xhrFields : {
    //     withCredentials: stager
    // },
    // headers: {token: sessionStorage.signature || ''},
    headers: {Authorization: localStorage.signature || ''},
    contentType: 'application/json',
    url: url,
    // xhrFields: {
    //     withCredentials: true
    // },
    // crossDomain: true,
    data: data || {},
    success: (_data) => {
        if(_data.code===2){
                localStorage.signature = '';
               window.location.href = _data.data;
        }else if(_data.msg&&_data.msg==='token已经过期'){
            window.location.href='/login';
        }else if(_data.code===-1&&auto==='all') {
            _warning({
                title: '错误信息',
                content: _data.msg || '服务器错误',
                style: {
                    top: 200
                },
                cancelText: ''
            });
        }else{
            success(_data);
        }
    },
    error: (_data) => {
        if(fail){
            try {
                fail(_data)
            }catch (e) {
                console.info(_data)
            }
        }
    }
  });
};

export default ajax;
