import React from 'react';

import Head from './common/head';
import linePic from '../assets/line-smooth.png';
import barPic from '../assets/bar-race-country.png';
import piePic from '../assets/pie-simple.png';
import lineBarPic from '../assets/line-bar.png';
import scatterPic from '../assets/scatter.png';
import wordcloudPic from '../assets/wordcloud.png';
import wordcloudPicR from '../assets/ratio-wordcloud.png';
import linePicR from '../assets/ratio-line-smooth.png';
import barPicR from '../assets/ratio-bar-race-country.png';
import piePicR from '../assets/ratio-pie-simple.png';
import lineBarPicR from '../assets/ratio-line-bar.png';
import scatterPicR from '../assets/ratio-scatter.png';
import PeakPic from '../assets/peak.png';
import editorPic from '../assets/editor.png';
// import channel from '../channel/channel';
import {Radio} from 'antd';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            data:[],
            flags:[],
            ratio:true,
            tab1:0,
            tab2:0,
        }
    }
  componentWillMount() {
        let href=window.location.href;
        let pathname=this.props.history.location.pathname;
        let path=pathname.split('/')[1];
        switch (path){
            case 'datavideo':
            case 'videoedit':
                window.location.href=href;
                break;
        }
  }
  componentDidMount(){

  }
  render() {
    return (
      <div>
          <Head history={this.props.history}/>
        <div className="login">
          <div className="board">
              <span>i@engrave.date</span>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
