import React from 'react';
import Logo from '../../assets/ic.png';
import {cloneConfig} from "../../util/handy";
import { Select ,Slider, InputNumber,Input,Popover } from 'antd';


class App extends React.Component {

  render() {
      console.info(this.props.history.location.pathname);
      let name=this.props.history.location.pathname;
    return (
        <div className="head box-shadow bg-w">
            <div className="title logo" onClick={()=>{
                window.location.href='/';
            }}>
                <img style={{width:'28px',margin:'5px'}} src={Logo} alt=""/>
                时光流韵
            </div>
            <div>
                <div className="head-tab">

              <span className={(name==='/tools'||name==='/')?'current':''} onClick={()=>{

                  this.props.history.push('/tools');
              }}>
                  工具箱
              </span>
                <span className={name==='/board'?'current':''} onClick={()=>{

                    this.props.history.push('/board');
                }}>
                      留言
                  </span>
                </div>
                {/*<span>*/}

                      {/*</span>*/}
                {/*<span className="text-username">*/}
                          {/*{localStorage.account||'用户名'}*/}
                      {/*</span>*/}
                {/*<span onClick={()=>{*/}
                    {/*localStorage.signature = '';*/}
                    {/*localStorage.account = '';*/}
                    {/*this.props.history.push('/login');*/}
                {/*}} className="text-loginOut">*/}
                          {/*退出*/}
                      {/*</span>*/}
            </div>
        </div>
    );
  }
}

export default App;
