import infoChannel from './request-shunt';

let _scope = '/dvapi';
let alias='/dvapi/';

export let scopeRender='http://data-video:8080'+alias;
if (process.env.NODE_ENV === 'production') {
    _scope = alias;
} else {
    _scope = 'http://180.76.152.187:28084'+alias;
  scopeRender=_scope;
}
export let scope=_scope;
const channel = (url, data, success, fail, auto) => {
  let _url = '';
  const _data = data;
  const _success = success;
  let _method = 'get';
  const _fail = fail;
  const async = true;
  switch (url) {
    case 'register': _url = '/api/user/register'; _method = 'post'; break;
    case 'login': _url = '/api/user/login'; _method = 'post'; break;
    case 'projectGet': _url = '/api/publish/get'; _method = 'get'; break;
    case 'get_publish': _url = '/api/get_publish'; _method = 'get';
        infoChannel(scopeRender+_url, _data, _success, _fail, _method, async,auto);
    return;
    case 'projectDel': _url = '/api/publish/del'; _method = 'get'; break;
    case 'projectSave': _url = '/api/publish/save'; _method = 'post'; break;
    case 'publishList': _url = '/api/publish/list'; _method = 'post';break;
    case 'chartList': _url = '/api/chart/list'; _method = 'post'; break;
    case 'enter': _url = '/api/user/init'; _method = 'get'; break;
    case 'user_info': _url = '/api/user/user_info'; _method = 'get'; break;
    case 'wordNlp': _url = '/api/third/wordNlp'; _method = 'post'; break;


    case 'get_user_info': _url = '/api/user'; _method = 'get'; break;
    case 'signup': _url = '/api/signup'; _method = 'post'; break;
    case 'signin': _url = '/api/signin'; _method = 'get'; break;
    case 'userinfo': _url = '/api/userinfo'; _method = 'get'; break;
    case 'wishlist': _url = '/api/wishlist'; _method = 'get'; break;
    case 'checkEmail': _url = '/api/checkEmail'; _method = 'get'; break;
    case 'sendEmail': _url = '/api/sendEmail'; _method = 'post'; break;
    case 'productList': _url = '/api/productlist'; _method = 'get'; break;
    case 'getCaptcha': _url = '/api/getCaptcha'; _method = 'get'; break;
    case 'report_error': _url = ''; _method = 'POST'; break;
    default: _url = url; break;
  }
  _url && (infoChannel(scope+_url, _data, _success, _fail, _method, async,auto));
};
export default channel;
