import React from 'react'
import {
    Route,
    Redirect,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom';
import Loadable from 'react-loadable';
import 'antd/dist/antd.css';
import './style/default.scss';
import './style/psi-storm.scss';
// import {set_refresh} from './components/navigation';
import Peak from './peak/peak';
import Board from './engrave/board';
import Fly from './engrave/fly';
import App from './route-data-to-video';

import PeakL from './peak/peak-list';
import createBrowserHistory from 'history/createBrowserHistory'


const history = createBrowserHistory();
let re='';
const Index = Loadable({
  loader: () => import('./engrave/index'),
  loading: () => <div>Loading...</div>
});
const Tools = Loadable({
    loader: () => import('./engrave/tools'),
    loading: () => <div>Loading...</div>
});
function enter (a,b,c){
    // $(window).scrollTop(0);
    // if(!localStorage.signature){
    //             window.location.href='/login';
    // }
}
const language='en';

function routeFilter(dom, props) {
    // if (!localStorage.signature) {
    //     history.push('/login');
    //     return <Login {...props} />
    // } else {
    //     return dom
    // }
}
export default class BasicExample extends React.Component {
    componentDidMount(){

        // set_refresh(()=>{
        //     this.setState({re:true})
        //     console.info('---refresh-----')
        // })
    }
    render() {
        return (
            <div className="outside">
                <Router basename="/" history={history} re={re}>
                    <Switch>
                        {/*根路径*/}
                        {/*<Route exact path="/" render={(props) => {*/}
                            {/*let dom = '';*/}
                            {/*if (localStorage.pure && localStorage.signature) {*/}
                                {/*dom = (<BarRace  {...props} />)*/}
                            {/*} else if (localStorage.pure) {*/}
                                {/*dom = (<Login  {...props} />)*/}
                            {/*}*/}
                            {/*return dom*/}
                        {/*}} />*/}
                        <Route path="/datavideo" component={App} />
                        <Route exact path="/"  component={Tools} />
                        {/*<Route path="/login" component={Login} />*/}
                        <Route path="/peak" component={Peak} />
                        <Route path="/board" component={Board} />
                        <Route path="/tools" component={Tools} />
                        <Route path="/peak-list" component={PeakL} />
                        <Route path="*" component={Fly} />
                    </Switch>
                </Router>
                {/*<Bottom history={history}/>*/}
            </div>
        );
    }
}
