import React from 'react';
import Logo from '../../assets/icp-logo.png';
import {cloneConfig} from "../../util/handy";
import { Select ,Slider, InputNumber,Input,Popover } from 'antd';
import {data} from "../datas/line-smooth";


class App extends React.Component {

  render() {
      // console.info(this.props.history.location.pathname);
      let name=this.props.history.location.pathname;
    return (
        <div className="head box-shadow">
            <div className="title">
                {/*<img style={{width:'28px',margin:'5px'}} src={Logo} alt=""/>*/}
                {/*数据新闻编辑器*/}
            </div>
            <div>
                      <span className="btn" onClick={()=>{

                          this.props.history.goBack();
                      }}>
                          返回
                      </span>
                {/*<span className="btn">*/}
                {/*下载*/}
                {/*</span>*/}
                {/*<span className="btn btn-color">*/}
                {/*保存视频*/}
                {/*</span>*/}
            </div>
        </div>
    );
  }
}

export default App;
