import React from 'react'
import {
    Route,
    BrowserRouter as Router,
    Switch
} from 'react-router-dom';
import Loadable from 'react-loadable';
import 'antd/dist/antd.css';
import './style/default.scss';
import {set_refresh} from './components/navigation';
import Login from './Home/login/index';
// import './style/iconfont/iconfont';
import into from './data-to-video/enter';
import createBrowserHistory from 'history/createBrowserHistory'


const history = createBrowserHistory();
let re='';
const data_to = Loadable({
  loader: () => import('./data-to-video'),
  loading: () => <div>Loading...</div>
});
// const pixi = Loadable({
//   loader: () => import('./data-to-video/pixi'),
//   loading: () => <div>Loading...</div>
// });
const Index = Loadable({
  loader: () => import('./data-to-video/index'),
  loading: () => <div>Loading...</div>
});
const ChartList = Loadable({
  loader: () => import('./data-to-video/chart-list'),
  loading: () => <div>Loading...</div>
});
const VideoList = Loadable({
  loader: () => import('./data-to-video/video-list'),
  loading: () => <div>Loading...</div>
});
const excel = Loadable({
  loader: () => import('./data-to-video/excel'),
  loading: () => <div>Loading...</div>
});
const BarRace = Loadable({
  loader: () => import('./data-to-video/bar-race-country'),
  loading: () => <div>Loading...</div>
});
const BarRaceTwo = Loadable({
  loader: () => import('./data-to-video/bar-race-two'),
  loading: () => <div>Loading...</div>
});
const LineSmooth = Loadable({
  loader: () => import('./data-to-video/line-smooth'),
  loading: () => <div>Loading...</div>
});
const PieSimple = Loadable({
  loader: () => import('./data-to-video/pie-simple'),
  loading: () => <div>Loading...</div>
});
const PieCustom = Loadable({
  loader: () => import('./data-to-video/pie-custom'),
  loading: () => <div>Loading...</div>
});
const PieRose = Loadable({
  loader: () => import('./data-to-video/pie-rose'),
  loading: () => <div>Loading...</div>
});
const Wordcloud = Loadable({
  loader: () => import('./data-to-video/wordcloud'),
  loading: () => <div>Loading...</div>
});
const Scatter = Loadable({
  loader: () => import('./data-to-video/scatter'),
  loading: () => <div>Loading...</div>
});
const LineBar = Loadable({
  loader: () => import('./data-to-video/line-bar'),
  loading: () => <div>Loading...</div>
});
const BarSimple = Loadable({
  loader: () => import('./data-to-video/bar-simple'),
  loading: () => <div>Loading...</div>
});
const BarPolar = Loadable({
  loader: () => import('./data-to-video/bar-polar'),
  loading: () => <div>Loading...</div>
});
const BarDelay = Loadable({
  loader: () => import('./data-to-video/bar-delay'),
  loading: () => <div>Loading...</div>
});
const BarGroup = Loadable({
  loader: () => import('./data-to-video/bar-group'),
  loading: () => <div>Loading...</div>
});
const LineGroup = Loadable({
  loader: () => import('./data-to-video/line-group'),
  loading: () => <div>Loading...</div>
});
const LineArea = Loadable({
  loader: () => import('./data-to-video/line-area'),
  loading: () => <div>Loading...</div>
});
const BarGroup2 = Loadable({
  loader: () => import('./data-to-video/bar-group2'),
  loading: () => <div>Loading...</div>
});
const BarRaceRender = Loadable({
  loader: () => import('./data-to-video/bar-race-country-render'),
  loading: () => <div>Loading...</div>
});
setTimeout(()=>{
    data_to.preload();
    excel.preload();
    BarRace.preload();
    BarRaceTwo.preload();
},600);
function enter (a,b,c){
    // $(window).scrollTop(0);
    // if(!localStorage.signature){
    //             window.location.href='/login';
    // }
}
const language='en';

function routeFilter(dom, props) {
    // if (!localStorage.signature) {
    //     history.push('/login');
    //     return <Login {...props} />
    // } else {
    //     return dom
    // }
}
export default class BasicExample extends React.Component {
    componentDidMount(){

        set_refresh(()=>{
            this.setState({re:true})
            console.info('---refresh-----')
        })
    }
    render() {
        return (
            <div className="outside">
                <Router basename="/datavideo/" history={history} re={re}>
                    <Switch>
                        {/*根路径*/}
                        {/*<Route exact path="/" render={(props) => {*/}
                            {/*let dom = '';*/}
                            {/*if (localStorage.pure && localStorage.signature) {*/}
                                {/*dom = (<BarRace  {...props} />)*/}
                            {/*} else if (localStorage.pure) {*/}
                                {/*dom = (<Login  {...props} />)*/}
                            {/*}*/}
                            {/*return dom*/}
                        {/*}} />*/}
                        <Route exact path="/"  component={Index} />
                        <Route path="/login" component={Login} />
                        <Route path="/enter" component={into} />
                        {/*<Route exact path="/" component={BarRace}/>*/}
                        {/*<Route path="/online" render={(props) => routeFilter(<Online  {...props} />, props)} />*/}
                        <Route exact path="/excel" component={excel} />
                        <Route exact path="/bar-race-country" component={BarRace} />
                        <Route exact path="/bar-race-two" component={BarRaceTwo} />
                        <Route exact path="/chart-list" component={ChartList} />
                        <Route exact path="/video-list" component={VideoList} />
                        <Route exact path="/bar-race-country" component={BarRace} />
                        <Route exact path="/line-smooth" component={LineSmooth} />
                        <Route exact path="/pie-simple" component={PieSimple} />
                        <Route exact path="/pie-custom" component={PieCustom} />
                        <Route exact path="/pie-rose" component={PieRose} />
                        <Route exact path="/wordcloud" component={Wordcloud} />
                        <Route exact path="/line-bar" component={LineBar} />
                        <Route exact path="/bar-simple" component={BarSimple} />
                        <Route exact path="/bar-polar" component={BarPolar} />
                        <Route exact path="/line-group" component={LineGroup} />
                        <Route exact path="/line-area" component={LineArea} />
                        <Route exact path="/bar-group" component={BarGroup} />
                        <Route exact path="/bar-delay" component={BarDelay} />
                        <Route exact path="/script-group" component={BarGroup2} />
                        <Route exact path="/scatter" component={Scatter} />
                        <Route exact path="/bar-race-country-render" component={BarRaceRender} />
                        {/*<Route exact path="/pixi" component={pixi} />*/}
                    </Switch>
                </Router>
                {/*<Bottom history={history}/>*/}
            </div>
        );
    }
}
