import React from 'react';
import $ from 'jquery';
import { message } from 'antd';
import channel from '../../channel/channel';
import './index.scss';

export default class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loginCodeImg: "",
            ticket: ""
        };
    }

    componentWillMount() {
        $(window).scrollTop(0);
        this.getLoginCode();
    }

    getLoginCode (){
        // channel('loginCode', '', (re) => {
        //     let img = "";
        //     let ticket = "";
        //     img = re.data && re.data.img;
        //     ticket = re.data && re.data.ticket;
        //     this.setState({ loginCodeImg: img, ticket });
        // }, '', 'infoMessage')
    }

    login() {
        const { ticket } = this.state;
        let name = $('.username').val();
        let pass = $('.password').val();
        let loginCode = $('.login-code').val();
        if (!name) {
            message.warning('用户名不能为空！');
            return;
        }
        if (!pass) {
            message.warning('密码不能为空！');
            return;
        }
        // if (!loginCode) {
        //     message.warning('验证码不能为空！');
        //     return;
        // }
        // channel('login',JSON.stringify({account:'test231',password:'test'}),()=>{
        //
        // },()=>{
        //
        // });
        channel('login', JSON.stringify({
            account: name,
             password: pass,
             // randstr: loginCode,
             }), (re) => {
            if(re.code===0){
                localStorage.signature = re.data;
                localStorage.account = name;
                localStorage.team = '';
                this.props.history.push('/chart-list');
            }else{
                message.warning(re.msg);
            }
        }, ()=>{
            this.getLoginCode();
        }, 'infoMessage')
    }
    render() {
        return (
            <div className="login-warp">
                <div className='login-content'>
                    <div className='login-left'>
                      <div>
                        <div>
                          LOGO
                        </div>
                        <div>
                          数据新闻
                        </div>
                      </div>
                    </div>
                    <div className='login-right'>
                        {/*<img src="/images/logo.png" height="50" width="auto" />*/}
                        <ul>
                            <li><input className="username" defaultValue='test231' placeholder="请输入用户名" type="text" /></li>
                            <li><input className="password" defaultValue='test'
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        this.login();
                                    }
                                }}
                                onKeyUp={(e) => {
                                    e.target.value = e.target.value.replace(/[\u4e00-\u9fa5]/ig, '');
                                }}
                                placeholder="请输入密码" type="password" />
                            </li>
                            {/*<li><input className="login-code" placeholder="验证码" type="text"/>*/}
                                {/*<span className="codeImg" onClick={()=>{*/}
                                    {/*this.getLoginCode()*/}
                                {/*}}>*/}
                                    {/*<img src={`data:image/jpeg;base64,${ loginCodeImg }` } alt="点击获取" />*/}
                                {/*</span>*/}
                            {/*</li>*/}
                            <li>
                                <span className="btn" onClick={() => {
                                    this.login();
                                }}>登录
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

