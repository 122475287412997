
/*
* 根据项目环境初始化项目配置
* */
// import "./power";
import React from 'react';
import ReactDOM from 'react-dom';
// import 'jquery-ui/themes/base/all.css';

// import './util/screenshot';
// import './css/iconfont/iconfont';
// import Route from './route-data-to-video';
import Route from './route-engrave';
// import Route from './route-peak';
// import {BrowserType} from './util/handy'
import registerServiceWorker from './registerServiceWorker';


ReactDOM.render(<Route />, document.getElementById('root'));
// registerServiceWorker();
// localStorage.bType=BrowserType();

