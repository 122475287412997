import React from 'react';
import $ from 'jquery';
import channel from '../channel/channel';
import { getParam } from "../util/handy";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            data:[],
            flags:[],
            tab1:0,
            tab2:0,
        }
    }
    componentWillMount() {
        $(window).scrollTop(0);
        let href = window.location.href;
        let token = getParam(href, 'token');
        if (token) {
            // localStorage.signature = token;
            channel('enter',{token:token},(re)=>{
                localStorage.signature = re.data;
                channel('user_info',{token:localStorage.signature},(re)=>{
                    localStorage.account = re.data['name']||'';
                    this.props.history.replace('/')

                },()=>{

                })
            },()=>{
                this.props.history.replace('/')
            },'all')
        } else {
            this.props.history.replace('/')
        }
    }
    render() {
        return (
            <div>
                正在进入数据新闻……
            </div>
        )
    }
};


export default App;
