import React from 'react';
import { HotTable } from '@handsontable/react';
import Handsontable from 'handsontable';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.handsontableData = Handsontable.helper.createSpreadsheetData(6, 10);

        this.data = [
            ["", "Ford", "Volvo", "Toyota", "Honda"],
            ["2016", 10, 11, 12, 13],
            ["2017", 20, 11, 14, 13],
            ["2018", 30, 15, 12, 13]
        ];
        // new Handsontable(element, {
        //     afterChange: (changes) => {
        //         changes.forEach(([row, prop, oldValue, newValue]) => {
        //             // Some logic...
        //         });
        //     }
        // })
    }
    change=(changes,b,c)=>{

                // changes.forEach(([row, prop, oldValue, newValue]) => {
                //     // Some logic...
                // });
        // console.info('changes');
        // console.info(changes);
        // console.info(b);
        // console.info(c);
        // this.props.changeData(changes);

    }
    componentDidMount(){
        // console.info(this.props.data);
    }

    render() {
        // console.info(this.props.data);
        return (
            <div id="hot-app">
                <HotTable
                    id="hot"
                    fixedRowsTop={1}
                    manualColumnResize={true}
                    manualRowResize={true}
                    manualColumnMove={true}
                    manualRowMove={true}
                    startRows={8}
                    allowInsertColumn={true}
                    allowRemoveColumn={true}
                    minRows={15}
                    minCols={4}
                    colWidths={180}
                    startCols={8}
                    autoRowSize={true}
                    autoColumnSize={true}
                    afterChange={this.change}
                    licenseKey='non-commercial-and-evaluation'
                    data={this.props.data}
                    colHeaders={true}
                    rowHeaders={true}
                    // width="600"
                    height="600"
                />
            </div>
        );
    }
}

export default App;
