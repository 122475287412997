import React from 'react';
import {Input,InputNumber} from 'antd';
import Hotable from './excel';
import {cloneConfig} from "../util/handy";
import {data} from './data';
import Head from "../data-to-video/common/headIn";


let config={
    level1:'',
    level2:'',
    level3:'',
    level4:'',

}
// const data =[["名称","值"],['搜索引擎',1048],['直接访问',735],['邮件营销',580],['联盟广告',484],['视频广告',300]];
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            data:cloneConfig(data),
            level1:['',0,0],
            level2:['',0,0],
            level3:['',0,0],
            level4:[0,0,0],
        }
    }
  componentWillMount() {

  }
  componentDidMount(){

  }
    changeData(){

    }
  render() {
    return (
      <div>
          <Head history={this.props.history}/>
          <Hotable changeData={this.changeData.bind(this)} data={this.state.data}/>
      </div>
    );
  }
}

export default App;
