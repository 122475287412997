import React from 'react';

import Head from './common/head';
import linePic from '../assets/line-smooth.png';
import barPic from '../assets/bar-race-country.png';
import piePic from '../assets/pie-simple.png';
import lineBarPic from '../assets/line-bar.png';
import scatterPic from '../assets/scatter.png';
import wordcloudPic from '../assets/wordcloud.png';
import wordcloudPicR from '../assets/ratio-wordcloud.png';
import linePicR from '../assets/ratio-line-smooth.png';
import barPicR from '../assets/ratio-bar-race-country.png';
import piePicR from '../assets/ratio-pie-simple.png';
import lineBarPicR from '../assets/ratio-line-bar.png';
import scatterPicR from '../assets/ratio-scatter.png';
import PeakPic from '../assets/peak.png';
import editorPic from '../assets/editor.png';
// import channel from '../channel/channel';
import {Radio} from 'antd';
import ba from "./common/ba.png";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            data:[],
            flags:[],
            ratio:true,
            tab1:0,
            tab2:0,
        }
    }
  componentWillMount() {

  }
  componentDidMount(){

  }
  render() {
      let tab1=[];
      // ['文案配置','智能配色','样式配置','背景替换'].forEach((v,k)=>{
      // ['全部','趋势','数量','对比','占比','排名','地理','分布'].forEach((v,k)=>{
      ['全部','趋势','对比','占比','排名'].forEach((v,k)=>{
          tab1.push(<div  key={k} className={this.state.tab1===k?'current':''} onClick={()=>{
              this.setState({tab1:k});
          }}>
              {v}
          </div>)

      });
      let tab3=[];
      let chart0={path:'peak',pic:this.state.ratio?PeakPic:PeakPic,name:'暗黑破坏神巅峰计算器'};
      let chart99={path:'videoedit',pic:this.state.ratio?editorPic:editorPic,name:'音视频在线编辑器'};
      let chart1={path:'datavideo/bar-race-country',pic:this.state.ratio?barPic:barPicR,name:'柱状动态排序图'};
      let chart2={path:'line-smooth',pic:this.state.ratio?linePic:linePicR,name:'折线图'};
      let chart3={path:'pie-simple',pic:this.state.ratio?piePic:piePicR,name:'饼图'};
      let chart4={path:'wordcloud',pic:this.state.ratio?wordcloudPic:wordcloudPicR,name:'词云'};
      let chart5={path:'scatter',pic:this.state.ratio?scatterPic:scatterPicR,name:'散点图'};
      let chart6={path:'line-bar',pic:this.state.ratio?lineBarPic:lineBarPicR,name:'柱状折线图'};
      switch (this.state.tab1){
          case 0:tab3=
              [
                  [chart0,chart1,chart2,chart3,chart4,chart5,chart6,chart99],
                  [chart2,chart6],
                  [chart1,chart6],
                  [chart3],
                  [chart5],
                  [chart4],
              ];break;
          case 1:tab3=
              [
                  [chart0,chart1,chart2,chart5,chart6,chart99],
                  [chart2,chart6],
                  [chart1,chart6],
                  [],
                  [chart5],
                  [chart4],
              ];break;
          case 2:tab3=
              [
                  [chart0,chart1,chart2,chart3,chart5,chart6,chart99],
                  [chart2,chart6],
                  [chart1,chart6],
                  [chart3],
                  [chart5],
                  [],
              ];break;
          case 3:tab3=
              [
                  [chart0,chart3,chart99],
                  [],
                  [],
                  [chart3],
                  [],
                  [],
              ];break;
          case 4:tab3=
              [
                  [chart0,chart1,chart2,chart3,chart6,chart99],
                  [chart2,chart6],
                  [chart1,chart6],
                  [chart3],
                  [],
                  [],
              ];break;
          default:break;
      }
      let tab2=[];
      let tablist=[];
      // ['文案配置','智能配色','样式配置','背景替换'].forEach((v,k)=>{
      // ['推荐','折线图','柱状图','折线柱状图','堆叠图','排名图','地图','词云','指示图','饼图','其他'].forEach((v,k)=>{
      // ['推荐','折线图','柱状图','折线柱状图','饼图','其他'].forEach((v,k)=>{
      ['推荐','折线图','柱状图','饼图','散点图','词云'].forEach((v,k)=>{
          if(tab3[k].length){
              tab2.push(<a href={'#'+v}><div  key={k} className={this.state.tab2===k?'current':''} onClick={()=>{
                  this.setState({tab2:k});
              }}>
                  {v}
              </div></a>);
              tablist.push(<div id={v} className="title-bar">
                  {v}
              </div>);
              let list=[];
              tab3[k].forEach((v,k)=>{
                      list.push(<div className="chart-unit" onClick={()=>{
                          this.props.history.push('/'+v.path+(!this.state.ratio?'#ratio=9:16':''));
                      }}>
                          <div>
                              <img src={v.pic} alt=""/>
                          </div>
                          <div className="name">
                              {v.name}
                          </div>
                      </div>)
              });
              tablist.push(<div className="list">
                  {list}
              </div>);
          }
      });
      let barRace0=(<div className="chart-unit" onClick={()=>{
          this.props.history.push('/bar-race-country');

      }}>
          <div>
              <img src={barPic} alt=""/>
          </div>
      </div>);
    return (
      <div>
          <Head history={this.props.history}/>
        <div className="login">
          <div className="board">
              <span>i@engrave.date</span>
          </div>
        </div>
          <div className="bottom">
              <div style={{width:'300px',margin:'0 auto', padding:'20px 0'}}>
                  <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010502005394" style={{display:'inline-block',textDecoration:'none',height:'20px',lineHeight:'20px'}}>
                      <img src={ba} style={{float:'left'}}/>
                      <p style={{float:'left',height:'20px',lineHeight:'20px',margin: '0px 0px 0px 5px', color:'#939393'}}>豫公网安备 41010502005394号</p></a>
              </div>
          </div>
      </div>
    );
  }
}

export default App;
